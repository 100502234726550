// Import dependencies
import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery, Link } from "gatsby";

// Import components
import SectionSeparator from "../separators/sectionSeparator";
import { NavLink, ParagraphText } from "../../styled-components/UILibrary";

// Import styles
import "./footer.css";

// Import images & icons
import LegalsAndDisclaimers from "../modal/legalsAndDisclaimers";
import LogoFooter from "../../assets/icons/logo_gray_footer.svg";

/*
    Footer component
*/
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeReinin: false,
      activeWebsite: false,
      activeUsers: false,
      activeDrivers: false,
      activeEnterprise: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  // Either opens or closes modal window depending on
  // the passed state parameter
  toggleModal = state => {
    this.setState({ modalVisible: state });
  };

  render() {
    return (
      <footer className="footer_container">
        <div className="footer_gradient"></div>
        <div className="footer">

        <div className="footer_logo_section">
          <Link
            to="/"
            aria-label="Home"
            style={{display: "flex", marginLeft: 0}}
          >
            <LogoFooter />
          </Link>

          <span className="footer_slogan">Make room for tomorrow</span>
            <div className="credits">
              <span>Reinin (Singapore) Pte. Ltd.</span>
              <span>UEN 201829982K</span>
              <span>Reinin © 2020-2022</span>
            </div>

        </div>
        <div className="footer_list_section">
          <div className={this.state.activeReinin ? "footer_list active" : "footer_list"}>
            <h1 onClick={() => this.setState({activeReinin: !this.state.activeReinin})}>
              Reinin
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.29006 0.709778C0.900059 1.09978 0.900059 1.72978 1.29006 2.11978L5.17006 5.99978L1.29006 9.87978C0.900059 10.2698 0.900059 10.8998 1.29006 11.2898C1.68006 11.6798 2.31006 11.6798 2.70006 11.2898L7.29006 6.69978C7.68006 6.30978 7.68006 5.67978 7.29006 5.28978L2.70006 0.699778C2.32006 0.319778 1.68006 0.319778 1.29006 0.709778Z" fill="#828282"/>
              </svg>
            </h1>
            <Link to="/about">About us</Link>
            <Link to="/how-it-works">How it works</Link>
            <a href="https://www.linkedin.com/company/reininsg">Careers</a>
            <Link to="/shop">Reinin Store</Link>
          </div>
          <div className={this.state.activeWebsite ? "footer_list active" : "footer_list"}>
            <h1 onClick={() => this.setState({activeWebsite: !this.state.activeWebsite})}>
              Website
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.29006 0.709778C0.900059 1.09978 0.900059 1.72978 1.29006 2.11978L5.17006 5.99978L1.29006 9.87978C0.900059 10.2698 0.900059 10.8998 1.29006 11.2898C1.68006 11.6798 2.31006 11.6798 2.70006 11.2898L7.29006 6.69978C7.68006 6.30978 7.68006 5.67978 7.29006 5.28978L2.70006 0.699778C2.32006 0.319778 1.68006 0.319778 1.29006 0.709778Z" fill="#828282"/>
              </svg>
            </h1>
            <a href="https://help.reinin.sg/">Service terms</a>
            <a href="https://help.reinin.sg/">Cookies policy</a>
            <a href="https://help.reinin.sg/">Privacy policy</a>
            <a>Sitemap</a>
          </div>
          <div className={this.state.activeUsers ? "footer_list active" : "footer_list"}>
            <h1 onClick={() => this.setState({activeUsers: !this.state.activeUsers})}>
              Users
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.29006 0.709778C0.900059 1.09978 0.900059 1.72978 1.29006 2.11978L5.17006 5.99978L1.29006 9.87978C0.900059 10.2698 0.900059 10.8998 1.29006 11.2898C1.68006 11.6798 2.31006 11.6798 2.70006 11.2898L7.29006 6.69978C7.68006 6.30978 7.68006 5.67978 7.29006 5.28978L2.70006 0.699778C2.32006 0.319778 1.68006 0.319778 1.29006 0.709778Z" fill="#828282"/>
              </svg>
            </h1>
            <Link to="/login">Login</Link>
            <Link to="/pricing">Membership</Link>
            <a href="https://help.reinin.sg/">Help center</a>
          </div>
          <div className={this.state.activeDrivers ? "footer_list active" : "footer_list"}>
            <h1 onClick={() => this.setState({activeDrivers: !this.state.activeDrivers})}>
              Drivers
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.29006 0.709778C0.900059 1.09978 0.900059 1.72978 1.29006 2.11978L5.17006 5.99978L1.29006 9.87978C0.900059 10.2698 0.900059 10.8998 1.29006 11.2898C1.68006 11.6798 2.31006 11.6798 2.70006 11.2898L7.29006 6.69978C7.68006 6.30978 7.68006 5.67978 7.29006 5.28978L2.70006 0.699778C2.32006 0.319778 1.68006 0.319778 1.29006 0.709778Z" fill="#828282"/>
              </svg>
            </h1>
            <Link to="/become-driver">Join us</Link>
            <a href="https://help.reinin.sg/">Driver terms</a>
            <a href="https://help.reinin.sg/">Help center</a>
          </div>
          <div className={this.state.activeEnterprise ? "footer_list active" : "footer_list"}>
            <h1 onClick={() => this.setState({activeEnterprise: !this.state.activeEnterprise})}>
              Enterprise
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.29006 0.709778C0.900059 1.09978 0.900059 1.72978 1.29006 2.11978L5.17006 5.99978L1.29006 9.87978C0.900059 10.2698 0.900059 10.8998 1.29006 11.2898C1.68006 11.6798 2.31006 11.6798 2.70006 11.2898L7.29006 6.69978C7.68006 6.30978 7.68006 5.67978 7.29006 5.28978L2.70006 0.699778C2.32006 0.319778 1.68006 0.319778 1.29006 0.709778Z" fill="#828282"/>
              </svg>
            </h1>
            <Link to="/claim-brand">Reinin for Brands</Link>
          </div>
        </div>
        </div>
      </footer>
    );
  }
}
